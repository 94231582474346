import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Home = () => {

    const [carouselData, setCarouselData] = useState([]);
    const [loading, setLoading] = useState(true);
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        // Fetch data from your API
        axiosInstance.get('/') // Replace with your actual API endpoint
            .then(response => {
                setCarouselData(response.data); // Assuming the response is an array of items
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axiosInstance.get('/posts/details'); 
                setPosts(response.data.posts);
            } catch (err) {
                console.error('Error fetching posts:', err);
            }
        };

        fetchPosts();
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === posts.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? posts.length - 1 : prevIndex - 1
      );
    };


    return (
        <>
           <Helmet>
 
      <title>Advisions Research and Development Private Limited (Advisions)
      </title>
      <meta
  name="description"
  content="Discover groundbreaking advancements in AI-driven R&D across defense, telecommunications, and quantum technologies. Our innovative solutions leverage cutting-edge artificial intelligence, quantum computing, and secure communication systems to empower industries with scalable, intelligent, and future-ready technologies. From quantum encryption and cybersecurity to autonomous systems and 5G networks, we specialize in creating state-of-the-art technologies that redefine innovation for global security and connectivity."
/>

<meta
  name="keywords"
  content="Advisions, Advisions Research and Development Private Limited (Advisions), Advisions Research and Development, Advisions Research and Development Pt Ltd (Advisions), AI R&D, defense technologies, telecommunications, quantum computing, artificial intelligence, secure networks, innovative solutions, machine learning, deep learning, neural networks, cybersecurity, quantum encryption, quantum networks, defense AI, telecom AI, IoT, 5G technologies, autonomous systems, robotics, natural language processing, big data analytics, predictive analytics, military technology, secure communications, advanced algorithms, satellite communications, edge computing, cloud computing, AI hardware, AI software, digital transformation, data privacy, blockchain in defense, secure AI, AI-powered defense systems, AI in telecom, quantum machine learning, quantum cryptography, AI strategy, intelligent automation, signal processing, radar technologies, surveillance systems, AI defense research, smart grids, AI-driven innovation, quantum processors, future technologies, AI ethics, defense-grade AI, AI security solutions, quantum research, advanced computing systems, AI for defense, secure telecom networks, AI innovations, predictive maintenance, AI-driven decision-making, technology solutions for defense, advanced military applications, AI in aerospace, AI for security, quantum AI integration, secure satellite systems, encryption technologies, AI-powered telecom, adaptive algorithms, real-time analytics, AI in defense research, defense innovation, advanced defense systems, telecommunications R&D, quantum infrastructure, AI for public safety, data-driven defense systems, national security technologies, AI-enhanced surveillance, quantum defense systems"
/>

      <meta name="author" content="Advisions Research and Development Private Limited (Advisions)" />
      <meta property="og:title" content="Innovating the Future: AI, Defense, Telecom & Quantum Technologies" />
      <meta
        property="og:description"
        content="Leading R&D company driving innovation in defense, telecommunications, AI, and quantum technologies. Learn about our groundbreaking solutions."
      />

      <meta property="og:url" content="https://www.advisionslab.com/" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Innovating the Future: AI, Defense, Telecom & Quantum Technologies" />
      <meta
        name="twitter:description"
        content="Discover the forefront of AI-driven R&D in defense, telecommunications, and quantum computing."
      />

      <link rel="canonical" href="https://www.advisionslab.com/" />
    </Helmet>
            <Navbar />

            <section id="hero" className='text-justify'>
                <div className="hero-container">
                    <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

                        <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                        <div className="carousel-inner" role="listbox">


                            {carouselData.map((item, index) => (
                                <div
                                    key={item._id}
                                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(${item.pic})` }} // Use item.pic for background image
                                >
                                    <div className="carousel-container">
                                        <div className="carousel-content container">
                                            <h2 className="animated fadeInDown">
                                                 <span>{item.name}</span>
                                            </h2>
                                            <p className="animated fadeInUp text-justify h6">
                                                {item.description}
                                            </p>
                                            <a href="#about" className="btn-get-started animated fadeInUp scrollto">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}




                        </div>

                        <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>

                    </div>
                </div>
            </section>

            <main id="main" className='text-justify'>


                <section id="about" className="about">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-6 video-box">
                                <img src="assets2/img/about.jpg" className="img-fluid" alt="" />
                                <a href="https://www.youtube.com/watch?v=S42S_a6VFXA" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                            </div>

                            <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
                                <div className="section-title">
                                    <h2>About Us</h2>
                                    <p className='text-center'>Leading tech company pioneering innovative solutions in diverse domains. At Advisions R&D, we are a leading tech and research company dedicated to providing innovative services and solutions across various fields.</p>
                                </div>

                                <div className="icon-box" >
                                    <div className="icon"><i className="bx bx-rocket"></i></div>
                                    <h4 className="title"><a href="">Generative AI Development</a></h4>
                                    <p className="description text-justify">Specializing in tailored ChatGPT development and integration, along with advanced large language models (LLMs) to drive innovation.</p>
                                </div>

                                <div className="icon-box" >
                                    <div className="icon"><i className="bx bx-code"></i></div>
                                    <h4 className="title"><a href="">Custom Development</a></h4>
                                    <p className="description text-justify">Offering custom web and mobile app development, secure enterprise software, MVP development, and strategic consulting.</p>
                                </div>

                                <div className="icon-box" >
                                    <div className="icon"><i className="bx bx-lock"></i></div>
                                    <h4 className="title"><a href="">Education & Training</a></h4>
                                    <p className="description text-justify">Providing school management software, STEM programs, ATL lab setup, corporate training, and robotics process automation.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>





                <div className="container">
                    <div className="row text-center">

                        <div className="col-lg-3 col-md-6 mb-4" data-aos="fade-up">
                            <div className="count-box py-4 px-3 bg-light rounded shadow-sm">
                                <i className="icofont-simple-smile" style={{ color: "#20b38e", fontSize: "2rem" }}></i>
                                <h3 className="my-3" data-toggle="counter-up">232</h3>
                                <p className="mb-0">Happy Clients</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="count-box py-4 px-3 bg-light rounded shadow-sm">
                                <i className="icofont-document-folder" style={{ color: "#c042ff", fontSize: "2rem" }}></i>
                                <h3 className="my-3" data-toggle="counter-up">521</h3>
                                <p className="mb-0">Projects</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="count-box py-4 px-3 bg-light rounded shadow-sm">
                                <i className="icofont-live-support" style={{ color: "#46d1ff", fontSize: "2rem" }}></i>
                                <h3 className="my-3" data-toggle="counter-up">1,463</h3>
                                <p className="mb-0">Hours Of Support</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4" data-aos="fade-up" data-aos-delay="600">
                            <div className="count-box py-4 px-3 bg-light rounded shadow-sm">
                                <i className="icofont-users-alt-5" style={{ color: "#ffb459", fontSize: "2rem" }}></i>
                                <h3 className="my-3" data-toggle="counter-up">25</h3>
                                <p className="mb-0">Hard Workers</p>
                            </div>
                        </div>

                    </div>
                </div>


                

                <section className="py-5">
                    <div className="container">
                        <div className="text-center mb-4">
                            <h2 className="display-6 text-primary">Why Choose Us</h2>
                            <p className="lead">
                                We're Best in AI Industry with 5+ Years of Experience
                            </p>
                        </div>

                        <div className="row">
                            {/* Main card */}
                            <div className="col-md-12 mb-4">
                                <div className="card shadow-lg border-0">
                                    <div className="row g-0 align-items-center text-justify">
                                        {/* Image or Illustration */}
                                        <div className="col-md-4">
                                            <img
                                                src="assets/img/feature.jpg"
                                                alt="AI Team"
                                                className="img-fluid rounded-start h-100"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h4 className="card-title text-primary">
                                                    Choose us for AI Tech and Software
                                                </h4>
                                                <p className="card-text">
                                                    Expertise, innovation, proven track record, scalable
                                                    solutions, customer-centric approach, robust support,
                                                    cost-effectiveness, security, and a collaborative
                                                    partnership.
                                                </p>

                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">
                                                        <i className="bi bi-award-fill text-success me-2"></i>
                                                        We boast a team of industry-leading professionals
                                                    </li>
                                                    <li className="list-group-item">
                                                        <i className="bi bi-alarm-fill text-warning me-2"></i>
                                                        Timely, precise solutions delivered comprehensively with
                                                        efficacy
                                                    </li>
                                                    <li className="list-group-item">
                                                        <i className="bi bi-lightbulb-fill text-info me-2"></i>
                                                        Innovative product development with maintenance and
                                                        updates
                                                    </li>
                                                </ul>
                                                <div className="d-flex justify-content-around mt-4">
                                                    <div className="text-center">
                                                        <h3 className="text-primary">30+</h3>
                                                        <p className="text-muted">Innovative Products</p>
                                                    </div>
                                                    <div className="text-center">
                                                        <h3 className="text-primary">20,000+</h3>
                                                        <p className="text-muted">Users Served</p>
                                                    </div>
                                                    <div className="text-center">
                                                        <h3 className="text-primary">95%</h3>
                                                        <p className="text-muted">Customer Satisfaction</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="about" className="about">
    <div className="container">
        <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-4 mb-3">
                <img className="img-fluid rounded" loading="lazy" src="./assets/img/about-img-1.png" alt="" />
            </div>
            <div className="col-12 col-lg-8">
                <div className="row justify-content-xl-end">
                    <div className="col-12 col-xl-11">
                        <div className="section-title mt-4">
                            <h2>Our Mission & Vision</h2>
                            <p className="text-center">Our goal is to deliver innovative solutions to the global defence industry while bridging gaps for sustainable growth.</p>
                        </div>
                        <div className="accordion accordion-flush" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Mission
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show text-justify" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        To deliver innovative and tailored solutions to the global defence industry, enabling organizations to meet evolving challenges through advanced technologies, localized manufacturing, and strategic partnerships.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Vision
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        To become the most trusted and sought-after consultancy company in the defence sector by fostering indigenous capabilities, promoting technology transfer, and bridging the gap between academia and industry for sustainable and strategic growth.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Innovation and Excellence
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        We believe in constantly innovating and striving for excellence in all areas, ensuring that we stay at the forefront of the defence industry to better serve our clients.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="py-5">
    <div className="container">
        <div className="row">
            {/* Main card */}
            <div className="col-md-12 mb-4">
                <div className="card shadow-lg border-0">
                    <div className="row g-0 align-items-center text-justify">
                        <div className="col-md-12">
                            <div className="card-body">
                                <h2 className=" text-primary mt-4 text-center mb-2">
                                    Our Work with the Defence Sector
                                </h2>
                                <p className=" p-3">
                                    With a focus on innovation and strategic solutions, we’ve partnered with the defense sector to drive technological advancements. Our work includes providing cutting-edge AI, software solutions, and tactical advancements to support national security and defense operations.
                                </p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-award-fill text-success me-2"></i>
                                                Collaborated with leading defense agencies on advanced technology
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-alarm-fill text-warning me-2"></i>
                                                Delivered mission-critical systems and software for defense readiness
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-lightbulb-fill text-info me-2"></i>
                                                Developed and implemented secure communication systems for defense
                                            </li>
                                        </ul>
                                    </div>

                                    {/* New list items for the right side */}
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-shield-lock-fill text-danger me-2"></i>
                                                Developed advanced cybersecurity systems for defense infrastructure
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-gear-fill text-primary me-2"></i>
                                                Engineered real-time data analytics tools for tactical operations
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-pencil-fill text-warning me-2"></i>
                                                Provided strategic consultancy on defense sector innovations
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-end mb-4">
          <a href="/defenceWork" className="text-primary font-weight-bold mt-4">View Details <i className="fas fa-arrow-right ml-2"></i></a>
        </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
   
    </div>
</section>



<section className="py-5">
    <div className="container">
        <div className="row">
            {/* Main card */}
            <div className="col-md-12 mb-4">
                <div className="card shadow-lg border-0">
                    <div className="row g-0 align-items-center text-justify">
                        <div className="col-md-12">
                            <div className="card-body">
                                <h2 className=" text-primary mt-4 text-center mb-2">
                                    Our Work with the Telecommunications Sector
                                </h2>
                                <p className=" p-3">
                                    With a focus on innovation and enhancing connectivity, we’ve partnered with leading telecom companies to drive the next generation of communications solutions. Our work includes providing advanced infrastructure, data analytics tools, and next-gen communication technologies to improve performance and customer experience.
                                </p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-award-fill text-success me-2"></i>
                                                Partnered with major telecom companies to modernize their infrastructure
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-alarm-fill text-warning me-2"></i>
                                                Delivered advanced network optimization tools to improve connectivity
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-lightbulb-fill text-info me-2"></i>
                                                Developed innovative solutions for enhancing mobile network performance
                                            </li>
                                        </ul>
                                    </div>

                                    {/* New list items for the right side */}
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-shield-lock-fill text-danger me-2"></i>
                                                Designed and implemented secure communication protocols
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-gear-fill text-primary me-2"></i>
                                                Developed real-time network monitoring systems for service providers
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-pencil-fill text-warning me-2"></i>
                                                Provided strategic consultancy on next-gen telecom technologies like 5G
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-end mb-4">
                                    <a href="/telecomWork" className="text-primary font-weight-bold mt-4">View Details <i className="fas fa-arrow-right ml-2"></i></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</section>


<section className="py-5">
    <div className="container">
        <div className="row">
            {/* Main card */}
            <div className="col-md-12 mb-4">
                <div className="card shadow-lg border-0">
                    <div className="row g-0 align-items-center text-justify">
                        <div className="col-md-12">
                            <div className="card-body">
                                <h2 className="text-primary mt-4 text-center mb-2">
                                    Our Work with AI and Machine Learning
                                </h2>
                                <p className="p-3">
                                    We are at the forefront of AI and Machine Learning innovations with <strong>Mr. Santosh Kr. Singh(9+ years of experience in AI/ML & VLSI design)</strong>, helping industries harness the power of intelligent systems. From predictive analytics to automation, our work empowers organizations to make data-driven decisions, streamline operations, and unlock new business opportunities.
                                </p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-award-fill text-success me-2"></i>
                                                Developed AI solutions to automate complex processes
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-alarm-fill text-warning me-2"></i>
                                                Implemented machine learning models for predictive analytics in real-time
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-lightbulb-fill text-info me-2"></i>
                                                Created personalized recommendation systems for businesses
                                            </li>
                                        </ul>
                                    </div>

                                    {/* New list items for the right side */}
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-shield-lock-fill text-danger me-2"></i>
                                                Built AI-powered security solutions for fraud detection and risk mitigation
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-gear-fill text-primary me-2"></i>
                                                Engineered machine learning algorithms to optimize operational efficiency
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-pencil-fill text-warning me-2"></i>
                                                Provided consultancy on integrating AI and ML into business strategies
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-end mb-4">
                                    <a href="/aiWork" className="text-primary font-weight-bold mt-4">View Details <i className="fas fa-arrow-right ml-2"></i></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</section>

<section className="py-5">
    <div className="container">
        <div className="row">
            {/* Main card */}
            <div className="col-md-12 mb-4">
                <div className="card shadow-lg border-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-md-12">
                            <div className="card-body">
                                <h2 className="text-primary mt-4 text-center mb-2">
                                    Our Work with Quantum Technology
                                </h2>
                                <p className="p-3">
                                    With a focus on advancements in quantum computing and quantum communication, we’ve partnered with industry leaders and under the guidance of <strong>Professor Angshuman Karmakar from IIT Kanpur</strong> to push the boundaries of what is possible. Our work involves developing quantum algorithms, secure communication systems, and computational solutions to unlock the future of industries like finance, healthcare, and telecommunications.
                                </p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-award-fill text-success me-2"></i>
                                                Developed quantum algorithms to solve complex optimization problems
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-alarm-fill text-warning me-2"></i>
                                                Implemented quantum communication protocols for secure data transmission
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-lightbulb-fill text-info me-2"></i>
                                                Advanced quantum computing models for simulating complex molecular structures
                                            </li>
                                        </ul>
                                    </div>

                                    {/* New list items for the right side */}
                                    <div className="col-md-6">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className="bi bi-shield-lock-fill text-danger me-2"></i>
                                                Designed quantum encryption systems for ultra-secure communication
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-gear-fill text-primary me-2"></i>
                                                Pioneered quantum ML models for data analysis and predictions
                                            </li>
                                            <li className="list-group-item">
                                                <i className="bi bi-pencil-fill text-warning me-2"></i>
                                                Provided consultancy on integrating quantum solutions into business operations
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-end mb-4">
                                    <a href="/quantumWork" className="text-primary font-weight-bold mt-4">View Details <i className="fas fa-arrow-right ml-2"></i></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</section>



<section id="products" className="products about">
                <div className="container mb-4">
                    <div className='row'>
                    <div className='col-12'>
                    <div className="section-title">
                        <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3 mt-4">Our Image Gallery</div>
                        <h2 className="mb-4">Explore Our Gallery</h2>
                        <p className='text-center'>Discover our innovative products designed to cater to diverse needs, from educational solutions to cutting-edge AI technologies.</p>
                    </div>
      <div className="carousel">
        <div
          className="carousel-track"
          style={{
            transform: `translateX(-${currentIndex * (100 / 3)}%)`,
          }}
        >
          {posts.map((slide, index) => (
            <div className="carousel-slide" key={index}>
              <div className="card">
                <img
                  src={slide.imageUrl}
                  alt={slide.title}
                  className="img-fluid rounded shadow"
                />
                <div className="m-4">
                  <h5 className="card-title">{slide.title}</h5>
                  <p className="card-text">{slide.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <button className="carousel-btn prev" onClick={prevSlide}>
        ❮
      </button>
      <button className="carousel-btn next" onClick={nextSlide}>
        ❯
      </button>
      <div className="d-flex justify-content-end align-items-end mb-4">
     <a href="/galleryPage" className="text-primary font-weight-bold mt-4">View Details <i className="fas fa-arrow-right ml-2"></i></a>
    </div>
</div>
  </div>
</div>
</section>




                <div className="newsletter-subscribe container">
                    <div className="container">
                        <div className="intro">
                            <h2 className="text-center newsletter">Stay Updated with Our Latest News</h2>
                            <p className="text-center">
                                Sign up for our newsletter to receive the latest updates, exclusive offers, and insightful content directly to your inbox. Join our community and stay ahead with the most recent information and updates.
                            </p>
                        </div>
                        <form className="form-inline" method="post">
                            <div className="form-group">
                                <input className="form-control" type="email" name="email" placeholder="Enter your email" />
                            </div>
                            <div className="form-group">
                                <button className="btn" type="button" style={{ backgroundColor: '#1c5c93', color: '#fff', border: 'none' }}>Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>

            </main>



            <Footer />


        </>
    )
}

export default Home
